import React from 'react';

import lazy from 'Services/Lazy'
import routes from 'Page/Routes';

const IndexMasjid = lazy(() => import('./Masjid'), 'Index')
const CreatMasjid = lazy(() => import('./Masjid'), 'Create')
const UpdatMasjid = lazy(() => import('./Masjid'), 'Update')

const IndexMuballigh = lazy(() => import('./Muballigh'), 'Index')
const CreatMuballigh = lazy(() => import('./Muballigh'), 'Create')
const UpdatMuballigh = lazy(() => import('./Muballigh'), 'Update')

routes.group({path : 'kontak', option : {layout : 'admin', extend: 'Auth', permission : true}}, function (routes : any) {
    routes.group({path : 'masjid'}, function (routes : any) {
        routes.add({ 
            path : '', 
            element : <IndexMasjid />,
            option : { name : 'kontak/masjid', title : 'Ceramah Ramadhan' }
        })
        
        routes.modal({
            path : '/new', 
            element: <CreatMasjid />, 
            option: { name : 'kontak/masjid/new', title: 'Tambah Data'} 
        })

        routes.modal({
            path : '/edit', 
            element: <UpdatMasjid />, 
            option: { name : 'kontak/masjid/$id/edit', title: 'Edit Data'} 
        })
    })

    routes.group({path : 'muballigh'}, function (routes : any) {
        routes.add({ 
            path : '', 
            element : <IndexMuballigh />,
            option : { name : 'kontak/muballigh', title : 'Ceramah Ramadhan' }
        })
        
        routes.modal({
            path : '/new', 
            element: <CreatMuballigh />, 
            option: { name : 'kontak/muballigh/new', title: 'Tambah Data'} 
        })

        routes.modal({
            path : '/edit', 
            element: <UpdatMuballigh />, 
            option: { name : 'kontak/muballigh/$id/edit', title: 'Edit Data'} 
        })
    })
})
