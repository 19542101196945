import React, { createContext } from 'react'
import { Helmet } from 'react-helmet-async';

import { LoadingInt, FooterInt, Global, Layout, Permission } from 'Interface'

export { Loading, Footer }

export const GlobalContext = createContext<Partial<Global>>({});
export const LayoutContext = createContext<Partial<Layout>>({});
export const PermissionContext = createContext<Partial<Permission>>({});

function Loading({className, style, loading, title} : LoadingInt){
    const Children = () => (
        <>{title && 
            <Helmet>
                <title>{'Loading - ' + websiteName}</title>
            </Helmet>}
            <div className={"text-muted" + (className ? ' ' + className : '')} style={style}>
                <i className="fa fa-spinner fa-spin text-primary" style={{ fontSize : "25px"}}></i>
            </div>
        </>
    )
    if(loading) return <div className="loading"><Children /></div>
    return <Children />
}

function Footer({className} : FooterInt){
    const yearNow = new Date().getFullYear();

    return (
        <div className={"col-md-12" + (className ? ' ' + className : '')}>
            {(yearNow === createdAt ? yearNow : createdAt + ' - ' + yearNow)} &copy; {websiteName} by <a href={companyWeb}>{companyName}</a>
        </div>
    )
}