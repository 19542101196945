import React, { useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { Route, useParams, useLocation, matchPath } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet-async';

import { api, wrapPromise } from 'Services/Axios'
import { Group, Add, AddModal } from './Interface'
import { GlobalContext, LayoutContext, PermissionContext } from 'Component'
import Authentication from "Services/Authentication";

const Error = React.lazy(() => import('Template/Error'));

class Routes {

    public route : any = [];
    public modals : any = [];
    public parent : any = [];
    public option : any = [];
    public grouproute : any;

    group ({path, option = {}} : Group, add : Function){
        var oldGroup = this.grouproute
        if(this.grouproute){
            path = this.grouproute.path + '/' + path
            Object.assign(option, this.grouproute.option)
        }
        this.grouproute = { path : path, option: option }
        add(this)
        this.grouproute = oldGroup
    }

    add({ path, element, option } : Add){
        if(this.grouproute){
            path = '/' + this.grouproute.path + path
            option = {...this.grouproute.option, ...option}
        }

        this.route.push(
            {
                path: path,
                element: element,
                ...option
            }
        )
    }

    modal({ path, element, option } : AddModal){
        if(this.grouproute){
            path = '/' + this.grouproute.path + path
            Object.assign(option, this.grouproute.option)
        }
        this.modals.push(
            {
                path : path,
                element: element,
                ...option
            }
        )
    }

    get(layout = 'base'){
        var respond : any = []
        var newRoute = this.route.filter((route : any) => route.layout === layout)
        newRoute.forEach((node : any, index: any) => {
            if(node.extend && !this.parent.find((route : any) => route.path === baseName + node.path)){
                this.parent.push({path : baseName + node.path, parent : node.extend})
            }
            let permission = {
                title : node.title,
                ishorizontal : node.ishorizontal,
                path : node.name,
                permission : node.permission
            }
            respond.push(<Route 
                path={node.path}
                element={<Permission {...permission} >{node.element}</Permission>} key={index}/>)
        })
        return respond
    }

    getModal(path = '', option : any = {}){
        var newModal = this.modals.find((route : any) => route.path === path)
        this.option.push({path : path, option : option})

        return {
            title : newModal.title,
            permission : newModal.permission,
            data : newModal.name,
            option : option,
            element : newModal.element
        }
    }
    
    getParent(path = baseName){
        return this.parent.find((route : any) => matchPath(route.path, path))
    }

    getRoute(){
        return this.route
    }

    getModalOption(path = baseName){
        var result = this.option.find((route : any) => route.path === path)
        return result.option || null
    }
}

function Permission({children, title, ishorizontal, path, permission} : any){
    let { id } = useParams();
    let location = useLocation()
    const { setGlobal } = useContext(GlobalContext);
    const { setLayout } = useContext(LayoutContext);
    const results = useMemo(() => {
        let newPath = path.replace("$id", id)
        return permission && 
        wrapPromise(api.get(newPath + (location.search ? location.search : '')))
    }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ,[path, id])

    const [page, setPage] = useState(results)

    const ErrorFallback = useCallback(({error, resetErrorBoundary}) => {
        let title = error.message
        let buttonName = "Try Again"
        let Children = () => {
            return (
                <>
                    Why not try refreshing your page? or you can contact <a href="extras-contact.html" className="text-primary">support</a> 
                </>
            )
        }
        if (error.response) {
            var data = error.response.data
            title = data.hint
            switch (data.code) {
                case 9:
                    setGlobal({
                        type: 'costumize',
                        loading : false,
                        showAlert: true,
                        dataAlert : {
                            title: data.hint,
                            text: data.messages,
                            type: 'error',
                            confirmButtonColor: "#ff3333",
                            confirmButtonText: "OK",
                            windowAction : () => {
                                if(!Authentication.signout()) {
                                    setGlobal({type : 'checkUser'})
                                }
                            }
                        }
                    })
                    return (<></>)
            }
        }
        return (
            <Error title={title} img="images/animat-customize-color.gif" buttonName={buttonName} buttonClick={resetErrorBoundary}>
                <Children />
            </Error>
        )
    },[setGlobal])

    const newPage = useCallback(() => (
        setPage(
            permission && wrapPromise(api.get(path + (id ? '/' + id : '') + (location.search ? location.search : '')))
        )
        
    ), [path, permission, location.search, id]) 

    useEffect(() => {
        // didmount
        typeof ishorizontal === 'boolean' && setLayout({ type: 'horizontalToogle', ishorizontal : ishorizontal })
    }, [setLayout, ishorizontal]);

    return useMemo(() => {
        return (
            <ErrorBoundary fallbackRender={ErrorFallback} onReset={() => newPage()}>
                <Helmet>
                    <title>{title + ' - ' + websiteName}</title>
                </Helmet>
                {permission ? 
                <Page data={page} id={id}>
                    {children}
                </Page> : 
                children} 
            </ErrorBoundary>
        )
    },[ErrorFallback, permission, title, children, id, page, newPage])
}

function Page({ data, id, children } : any){
    const page = data.read().data

    return (
        <PermissionContext.Provider value={{ page, id }}>
            {children}
        </PermissionContext.Provider>
    )
}

export default new Routes()
