import Cookies from 'universal-cookie';

const cookie = new Cookies()

class CookiesServices {

    get(key: string){
        return cookie.get(key)
    }

    set(key: string, value: string, option: Object){
        cookie.set(key, value, option)
    }

    remove(key: string, option?: Object){
        cookie.remove(key, option)
    }
}

export default new CookiesServices()