import Cookies from './Cookies'

class Auth {

    isAuthenticated() {
        const token = Cookies.get(process.env.REACT_APP_TOKEN || '');
        return (token) ? true : false;
    }

    authenticate(cb : Function, response? : any) {
        let date = new Date();
        date.setTime(date.getTime() + response.expires_in * 1000);
        const option = { path : '/', expires: date }
        
        Cookies.set(process.env.REACT_APP_TOKEN || '', response.token_type+' '+response.access_token, option)
        
        cb(true);
    }

    signout() {
        Cookies.remove(process.env.REACT_APP_TOKEN || '', { path : '/'})
        return this.isAuthenticated()
    }
}

export default new Auth()
