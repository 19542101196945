import React from 'react';

import lazy from 'Services/Lazy'
import routes from 'Page/Routes';

const IndexCeramah = lazy(() => import('./Ceramah'), 'Index')
const CreateCeramah = lazy(() => import('./Ceramah'), 'Create')
const ReportCeramah = lazy(() => import('./Ceramah'), 'Report')
const UpdateCeramah = lazy(() => import('./Ceramah'), 'Update')
const HalamanCeramah = lazy(() => import('./Ceramah'), 'Halaman')

const IndexJudul = lazy(() => import('./CeramahJudul'), 'Index')
const CreateJudul = lazy(() => import('./CeramahJudul'), 'Create')
const UpdateJudul = lazy(() => import('./CeramahJudul'), 'Update')
const DetailCeramah = lazy(() => import('./DaftarCeramah'), 'Index')
const CreateJadwal = lazy(() => import('./DaftarCeramah'), 'Create')

const IndexKhutbah = lazy(() => import('./Khutbah'), 'Index')
const CreateKhutbah = lazy(() => import('./Khutbah'), 'Create')
const ReportKhutbah = lazy(() => import('./Khutbah'), 'Report')
const HalamanKhutbah = lazy(() => import('./Khutbah'), 'Halaman')

const DetailKhutbah = lazy(() => import('./DaftarKhutbah'), 'Index')
const KhutbahJadwal = lazy(() => import('./DaftarKhutbah'), 'Create')

const IndexLebaran = lazy(() => import('./KhutbahRaya'), 'Index')
const CreateLebaran = lazy(() => import('./KhutbahRaya'), 'Create')
const UpdateLebaran = lazy(() => import('./KhutbahRaya'), 'Update')

const PDFCeramah = React.lazy(() => import('./CeramahPDF'));
const LaporanCeramah = React.lazy(() => import('./CeramahLaporan'));
const CetakCeramah = React.lazy(() => import('./CeramahCetak'));

const PDFKhutbah = React.lazy(() => import('./KhutbahPDF'));
const LaporanKhutbah = React.lazy(() => import('./KhutbahLaporan'));
const CetakKhutbah = React.lazy(() => import('./KhutbahCetak'));

routes.group({path : 'jadwal', option : {layout : 'admin', extend: 'Auth', permission : true}}, function (routes : any) {
    routes.group({path : 'ceramah'}, function (routes : any) {
        routes.add({ 
            path : '', 
            element : <IndexCeramah />,
            option : { name : 'jadwal/ceramah', title : 'Ceramah Ramadhan' }
        })
        
        routes.add({ 
            path : '/judul', 
            element : <IndexJudul />,
            option : { name : 'jadwal/ceramah/judul', title : 'Judul Ceramah' }
        })

        routes.add({
            path : '/detail/:id',
            element: <DetailCeramah />, 
            option: { name : 'jadwal/ceramah/daftar/$id', title: 'Detail Data'} 
        })

        routes.add({
            path : '/pdf/:id',
            element: <PDFCeramah />, 
            option: { name : 'jadwal/ceramah/daftar/pdf/$id', title: 'Ceramah PDF', layout : 'base'} 
        })

        routes.add({
            path : '/cetak',
            element: <CetakCeramah />, 
            option: { name : 'jadwal/ceramah/cetak', title: 'Ceramah Cetak', layout : 'base'} 
        })

        routes.add({
            path : '/laporan/:id',
            element: <LaporanCeramah />, 
            option: { name : 'jadwal/ceramah/laporan/$id', title: 'Ceramah Cetak', layout : 'base'} 
        })

        routes.modal({
            path : '/new', 
            element: <CreateCeramah />, 
            option: { name : 'jadwal/ceramah/new', title: 'Tambah Data'} 
        })

        routes.modal({
            path : '/laporan', 
            element: <ReportCeramah />, 
            option: { name : 'jadwal/ceramah/laporan', title: 'Laporan Ceramah'} 
        })

        routes.modal({
            path : '/urutkan', 
            element: <HalamanCeramah />, 
            option: { name : 'jadwal/ceramah/laporan', title: 'Atur Halaman'} 
        })

        routes.modal({
            path : '/edit', 
            element: <UpdateCeramah />, 
            option: { name : 'jadwal/ceramah/$id/edit', title: 'Edit Data'} 
        })

        routes.modal({
            path : '/newJudul', 
            element: <CreateJudul />, 
            option: { name : 'jadwal/ceramah/judul/new', title: 'Tambah Data'} 
        })

        routes.modal({
            path : '/editJudul', 
            element: <UpdateJudul />, 
            option: { name : 'jadwal/ceramah/judul/$id/edit', title: 'Edit Data'} 
        })

        routes.modal({
            path : '/newDaftar', 
            element: <CreateJadwal />, 
            option: { name : 'jadwal/ceramah/daftar/new', title: 'Tambah Data'} 
        })
    })
    
    routes.group({path : 'khutbah'}, function (routes : any) {
        routes.add({ 
            path : '', 
            element : <IndexKhutbah />,
            option : { name : 'jadwal/khutbah', title : 'Khutbah Jum\'at' }
        })
        
        routes.add({ 
            path : '/lebaran', 
            element : <IndexLebaran />,
            option : { name : 'jadwal/khutbah/lebaran', title : 'Khutbah Hari Raya' }
        })

        routes.add({
            path : '/detail/:id',
            element: <DetailKhutbah />, 
            option: { name : 'jadwal/khutbah/daftar/$id', title: 'Detail Data'} 
        })

        routes.add({
            path : '/pdf/:id',
            element: <PDFKhutbah />, 
            option: { name : 'jadwal/khutbah/daftar/pdf/$id', title: 'Khutbah PDF', layout : 'base'} 
        })

        routes.add({
            path : '/cetak',
            element: <CetakKhutbah />, 
            option: { name : 'jadwal/khutbah/cetak', title: 'Khutbah Cetak', layout : 'base'} 
        })

        routes.add({
            path : '/laporan/:id',
            element: <LaporanKhutbah />, 
            option: { name : 'jadwal/khutbah/laporan/$id', title: 'Khutbah Cetak', layout : 'base'} 
        })

        routes.modal({
            path : '/new', 
            element: <CreateKhutbah />, 
            option: { name : 'jadwal/khutbah/new', title: 'Tambah Data'} 
        })

        routes.modal({
            path : '/laporan', 
            element: <ReportKhutbah />, 
            option: { name : 'jadwal/khutbah/laporan', title: 'Laporan Khutbah'} 
        })

        routes.modal({
            path : '/urutkan', 
            element: <HalamanKhutbah />, 
            option: { name : 'jadwal/khutbah/laporan', title: 'Atur Halaman'} 
        })

        routes.modal({
            path : '/newDaftar', 
            element: <KhutbahJadwal />, 
            option: { name : 'jadwal/khutbah/daftar/new', title: 'Tambah Data'} 
        })

        routes.modal({
            path : '/newLebaran', 
            element: <CreateLebaran />, 
            option: { name : 'jadwal/khutbah/lebaran/new', title: 'Tambah Data'} 
        })

        routes.modal({
            path : '/editLebaran', 
            element: <UpdateLebaran />, 
            option: { name : 'jadwal/khutbah/lebaran/$id/edit', title: 'Edit Data'} 
        })
    })
})
