import axios from 'axios'
import Cookies from './Cookies'

const guest = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: "json",
    headers: {
        'Content-Type': 'application/json', 
        "X-Requested-With": "XMLHttpRequest"
    }
});

const api = guest

api.interceptors.request.use((config) => {
    const token = Cookies.get(process.env.REACT_APP_TOKEN || '')
    if(token) config.headers['Authorization'] = token;

    return config
})

api.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // return response;

        return Promise.resolve(response);
    }, 
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    })

function wrapPromise(promise: Promise<any>) {
    let status = "pending";
    let result: any;
    let suspender = promise.then(
        (r : any) => {
            status = "success";
            result = r;
        },
        (e : any) => {
            status = "error";
            result = e;
        }
    );
    return {
        read() {
            if (status === "pending") {
                throw suspender;
            } else if (status === "error") {
                throw result;
            } else if (status === "success") {
                return result;
            }
        }
    };
}

function alertSuccess(setGlobal: any, option : any) {
    setGlobal({
        type: 'costumize', 
        showModal: false, 
        showAlert: true, 
        dataAlert : {
            title: 200,
            text: option.msg,
            type: 'success',
            confirmButtonColor: '#348cd4',
            confirmButtonText: "OK",
            windowAction : () => {
                option.success()
            },
        }
    })
}

function alertError(show: any, err : any) {
    let title = 404
    let message = err.message
    if (err.response) {
        var data = err.response.data
        title = err.response.status
        message = data.messages
    }
    show({type: 'costumize',
        loading : false,
        showAlert: true,
        dataAlert : {
            title: title,
            text: message,
            type: 'error',
            confirmButtonColor: "#ff3333",
            confirmButtonText: "OK",
        }
    })
}

export { guest, api, wrapPromise, alertSuccess, alertError }