import lazy from 'Services/Lazy'
import routes from 'Page/Routes';

const IndexLogin = lazy(() => import('./Login'), 'Index')
const IndexDashboard = lazy(() => import('./Dashboard'), 'Index')

routes.group({path : '', option : {layout : 'admin', extend: 'Auth', permission : true}}, function (routes : any) {
    routes.add({ 
        path : '', 
        element : <IndexDashboard />,
        option : {name : 'mgmt/dashboard', title : 'Dashboard'}
    })
})

routes.group({path : '', option : {layout : 'account', extend: 'NonAuth'}}, (routes : any) => {
    routes.add({ 
        path : 'login', 
        element : <IndexLogin />,
        option : { name: 'login', title: 'Login' }
    });
    // routes.add('/forget-password', '', { name: 'forgot-login', title: 'Forgot Password' });
})