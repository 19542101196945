import React, { Suspense, lazy, useReducer, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary'
import reportWebVitals from './reportWebVitals';

import { GlobalContext, Loading } from './Component';
import { globalReducer, initGlobal } from './Interface';
import Page from './Page';
import Authentication from "Services/Authentication";

window.baseName = process.env.PUBLIC_URL || '';
window.companyName = process.env.REACT_APP_COMPANY_NAME || '';
window.companyWeb = process.env.REACT_APP_COMPANY_WEB || '';
window.websiteName = process.env.REACT_APP_WEBSITE_NAME || '';
window.createdAt = 2019;

const Parent = React.lazy(() => import('Services/Parent'));
const Admin = React.lazy(() => import('Template/Admin'));
const Account = lazy(() => import('Template/Account'));
const Error = lazy(() => import('Template/Error'));

const SweetAlert = React.lazy(() => import('Libs/SweetAlert'));
const Modals = React.lazy(() => import('Libs/Modal'));

function App() {
	const [global, setGlobal] = useReducer(globalReducer, initGlobal)
	
	const ErrorFallback = useCallback(({error, resetErrorBoundary}) => {
		let title = error.message
		let buttonName = "Try Again"
		let handleError = () => resetErrorBoundary()
		if (error.response) {
			title =  error.response.data.hint
			buttonName = "Return To Login"
			handleError = () => {
				if(!Authentication.signout()) {
					resetErrorBoundary()
				}
			}
		}

		return (
			<Error title={title} img="images/animat-customize-color.gif" buttonName={buttonName} buttonClick={handleError}>
				Why not try refreshing your page? or you can contact <a href="extras-contact.html" className="text-primary">support</a> 
			</Error>
		)
	},[])

	return (
		<BrowserRouter>
			<HelmetProvider>
				<GlobalContext.Provider value={{ global, setGlobal }}>
					<Routes basename={baseName}>
						<Suspense fallback={<Loading title style={{position: 'absolute', top: '50%', left: '50%'}}/>}>
							<Route element={<ErrorBoundary 
												fallbackRender={ErrorFallback} 
												onReset={() => setGlobal({type : 'checkUser'})}><Parent /></ErrorBoundary>}>
								<Route element={<Admin />}>
									{Page.get('admin')}
								</Route>
								<Route element={<Account />}>
									{Page.get('account')}
								</Route>
								{Page.get('base')}
								<Route path="*" element={<Error homeBtn={true} img="images/animat-search-color.gif" title="Page Not Found">
									It's looking like you may have taken a wrong turn. Don't worry... it happens to the best of us. You might want to check your internet connection. Here's a little tip that might help you get back on track.
								</Error>} />
							</Route>
							{global.loading && <Loading className="text-center" loading={true} style={{ fontSize : "24px"}}/>}
							<Suspense fallback={<div className="loading"><Loading/></div>}>
                                { global.showAlert && <SweetAlert show={setGlobal} data={global.dataAlert} />}
                            </Suspense>
							<Suspense fallback={<div className="loading"><Loading/></div>}>
                                { global.showModal && <Modals show={setGlobal} data={global.dataModal} />}
                            </Suspense>
						</Suspense>
					</Routes>
				</GlobalContext.Provider>
			</HelmetProvider>
		</BrowserRouter>
	);
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
