import Authentication from "Services/Authentication";
import { api, wrapPromise } from 'Services/Axios'

// index.tsx
interface State {
    loading: boolean
    showAlert: boolean
    showModal: boolean
    dataAlert: Object
    dataModal: Object
	userDetail: Object
}

type Action = { type : 'loading'; value: boolean }
            | { type : 'checkUser'}
            | { type : 'showAlert'; dataAlert?: Object } 
            | { type : 'showModal'; dataModal?: Object } 
            | { type : 'hideAlert'}
            | { type : 'hideModal'}
			| { type : 'costumize';
                auth?: boolean;
                loading?: boolean; 
                showAlert?: boolean; 
                showModal?: boolean; 
                dataAlert?: Object;
                dataModal?: Object; }
      
export const initGlobal = {
    loading: false,
	showAlert : false,
	showModal : false,
	dataAlert : {},
	dataModal : {},
	userDetail : (Authentication.isAuthenticated() ? wrapPromise(api.get('')) : {})
};

export const globalReducer = (state : State, action : Action) : State => {
    switch (action.type) {
        case 'loading':
            return {
                ...state,
                loading : action.value
            }
        case 'checkUser':
            return {
                ...state,
                userDetail : Authentication.isAuthenticated() ? wrapPromise(api.get('')) : {}
            }
        case 'showAlert':
            return {
                ...state,
                showAlert : true,
                dataAlert : action.dataAlert || {} 
            }
        case 'showModal':
            return {
                ...state,
                showModal : true,
                dataModal : action.dataModal || {} 
            }
        case 'hideAlert':
            return {
                ...state,
                showAlert : false,
                dataAlert : {}
            }
        case 'hideModal':
            return {
                ...state,
                showModal : false,
                dataModal : {}
            }
        case 'costumize':
            return {
                ...state,
                userDetail : typeof action.auth === 'boolean' ? (action.auth ? wrapPromise(api.get('')) : {}) : state.userDetail,
                loading : typeof action.loading === 'boolean' ? action.loading : state.loading,
                showAlert: typeof action.showAlert === 'boolean' ? action.showAlert : state.showAlert,
                showModal: typeof action.showModal === 'boolean' ? action.showModal : state.showModal,
                dataAlert: action.dataAlert ? action.dataAlert : state.dataAlert,
                dataModal: action.dataModal ? action.dataModal : state.dataModal,
            }
    }
}

// Component.tsx
export type Global = { 
    global: any
    setGlobal: any
};

export type Layout = { 
    // setLoading : any
    setLayout? : any;
    state : any;
    // onChangeWp? : any;
    // confirmButton? : any
};

export type Permission = {  
    page : any
    setPage? : any
    id? : any
};

export interface LoadingInt {
    className? : string
    style? : React.CSSProperties
    loading? : boolean
    title? : boolean
}

export interface FooterInt {
    className? : string
}